@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";

// Bootstrap customization, where we're supposed to
$enable-rounded: false;
$enable-print-styles: false;

$body-bg: $black;
$body-color: $gray-100;
$link-color: $gray-100;
$link-decoration: underline;
$link-hover-color: $gray-500;
$link-hover-decoration: underline;

$theme-colors: map-merge($theme-colors, (
  primary: $white,
));

$list-group-bg: $black;
$list-group-border-color: $gray-300;

$table-border-color: $gray-900;

$modal-content-bg: $black;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$close-color: $white;
$close-text-shadow: none;

@import "node_modules/bootstrap/scss/bootstrap.scss";

// Bootstrap customization, where we're not supposed to

// Fix hover color being white
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value, $black);
  }
}

.nav-link {
  text-decoration: none;
}

.list-group-item {
  text-decoration: none;
}

.table thead th {
  border-bottom: 2px solid white;
  border-top: none;
}

.close {
  opacity: 1;
}

img.controller-icon {
	width: 40px;
	height: 40px;
  margin-top: -20px;
}
