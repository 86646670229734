.ListPage .list-group-item .delete {
  color: red;
  margin-left: 0.8em;
  font-size: 1.5em;
  line-height: 1em;
  position: relative;
  top: 2px;
}

.ListPage .list-group-item .delete:hover {
  color: darkred;
}
